@import url('https://p.typekit.net/p.css?s=1&k=urf7cpy&ht=tk&f=18441.18443.18445&a=19627688&app=typekit&e=css');

@font-face {
  font-family: 'azo-sans-web';
  src: url('https://use.typekit.net/af/7a7e75/00000000000000003b9afbb9/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/7a7e75/00000000000000003b9afbb9/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/7a7e75/00000000000000003b9afbb9/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('opentype');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: 'azo-sans-web';
  src: url('https://use.typekit.net/af/37ee84/00000000000000003b9afbbb/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/37ee84/00000000000000003b9afbbb/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/37ee84/00000000000000003b9afbbb/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('opentype');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'azo-sans-web';
  src: url('https://use.typekit.net/af/9dc3b1/000000000000000000013f8f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/9dc3b1/000000000000000000013f8f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/9dc3b1/000000000000000000013f8f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-style: normal;
  font-weight: 400;
}

html,
body {
  margin: 0;
  color: white;
  font-family: 'azo-sans-web', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.login-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  background: #061b32;

  .left-wrapper {
    flex: 8;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .company-id {
    display: flex;
    flex-direction: row;
    align-items: center;    
  }

  .background-image {
    flex: 4;
    background-image: url('/assets/KALUZA-connection.svg');
    background-size: cover;
    background-position-y: center;
    background-position-x: 0px;
  }

  .tagline {
    width: 100%;
    margin-left: 80px;
  }

  .company-id-text {
    margin-left: 20px;
    font-size: 24px;
  }
}
